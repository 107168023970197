import type { ApolloClient, FetchResult } from '@apollo/client';
import type { ApolloCache } from '@apollo/client/cache';
import { getCachedQueryVariables } from '@aurora/shared-apollo/cacheQueryVariables';
import nodeQuery from '@aurora/shared-client/components/nodes/NodeView.query.graphql';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';
import type { UpdateRolesInput } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  InviteType,
  MembershipType,
  SortDirection
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { ApolloQueryCacheKey, MembershipAction } from '../../types/enums';
import type {
  AcceptInviteMutationVariables,
  ContextNodeFragment,
  JoinNodeMutationVariables,
  LeaveNodeMutationVariables,
  MembershipInformationFragment,
  MembershipInvitesQueryVariables,
  MembershipListQuery,
  MembershipListQueryVariables,
  MembershipRequestApprovalMutationVariables,
  NodeRoleQuery,
  NodeRoleQueryVariables,
  RemoveMemberMutationVariables,
  UserViewFragment
} from '../../types/graphql-types';
import groupHubDetailsQuery from '../grouphubs/GroupHubDetails.query.graphql';
import nodeRoleQuery from '../nodes/nodeRole.query.graphql';
import userViewsQuery from '../users/UserViews.query.graphql';
import memberViewsQuery from './ManageMembershipForNodeTabbableWidget/MembershipListTab/MembershipList.query.graphql';

type Mutate = (Arguments) => Promise<FetchResult>;
type MutationPayload =
  | AcceptInviteMutationVariables
  | JoinNodeMutationVariables
  | LeaveNodeMutationVariables
  | RemoveMemberMutationVariables
  | MembershipRequestApprovalMutationVariables;

interface IUpdateNodeVariables {
  /**
   * Id of the node
   */
  nodeId: string;
  /**
   *
   * mutate for the membership action
   */
  mutate: Mutate;
  /**
   * mutation variables
   */
  mutationPayload: MutationPayload;
  /**
   * the membership node
   */
  membershipInformation: MembershipInformationFragment;
  /**
   * action taken
   */
  action: MembershipAction;
  /**
   * whether the current user is same as the user for whom the action is being taken for
   */
  isCurrentUser?: boolean;
  /**
   * Apollo client
   */
  client?: ApolloClient<object>;

  /**
   * Optional function to execute after a node update is completed.
   * @param cache
   */
  onUpdateCompleted?: (cache) => void;
}

const {
  JOIN_MEMBERSHIP_NODE,
  LEAVE_MEMBERSHIP_NODE,
  ACCEPT_INVITE,
  REMOVE_MEMBER,
  APPROVE_MEMBERSHIP_REQUEST,
  DENY_MEMBERSHIP_REQUEST,
  REQUEST_TO_JOIN,
  CANCEL_INVITE
} = MembershipAction;

/**
 * Provides the to be state of the membership node after action
 *
 * @param membershipInformation
 * @param action action taken
 * @param isCurrentUser whether the current user is same as the user for whom the action is being taken for
 * @return to be membership node state
 */
function getContextNodeAfterAction(
  membershipInformation: MembershipInformationFragment,
  action: MembershipAction,
  isCurrentUser: boolean
): MembershipInformationFragment {
  let { isMember, isInvited, membersCount, requestToJoinDate } = membershipInformation;
  let canJoin = checkPolicy(membershipInformation.membershipPolicies.canJoin);
  let canRequestToJoin = checkPolicy(membershipInformation.membershipPolicies.canRequestToJoin);
  switch (action) {
    case JOIN_MEMBERSHIP_NODE: {
      if (membershipInformation.membershipType === MembershipType.Open) {
        canJoin = false;
        isMember = true;
        membersCount += 1;
      } else if (membershipInformation.membershipType === MembershipType.Closed) {
        isMember = false;
        canRequestToJoin = false;
        requestToJoinDate = new Date();
      }
      break;
    }
    case LEAVE_MEMBERSHIP_NODE: {
      if (membershipInformation.membershipType === MembershipType.Open) {
        canJoin = true;
      }
      isMember = false;
      membersCount -= 1;
      requestToJoinDate = null;
      break;
    }
    case ACCEPT_INVITE: {
      isMember = true;
      isInvited = false;
      membersCount += 1;
      break;
    }
    case REMOVE_MEMBER: {
      membersCount -= 1;
      if (isCurrentUser) {
        canJoin = membershipInformation?.membershipType === MembershipType.Open;
        canRequestToJoin = membershipInformation?.membershipType === MembershipType.Closed;
        isMember = false;
      }
      break;
    }
    case APPROVE_MEMBERSHIP_REQUEST: {
      membersCount += 1;
      if (isCurrentUser) {
        isMember = true;
        canRequestToJoin = false;
      }
      break;
    }
    case DENY_MEMBERSHIP_REQUEST: {
      if (isCurrentUser) {
        canRequestToJoin = true;
      }
      break;
    }
    case REQUEST_TO_JOIN: {
      canRequestToJoin = false;
      requestToJoinDate = new Date();
      break;
    }
    default: {
      break;
    }
  }
  const updatedContextNode = {
    ...membershipInformation,
    membersCount,
    isMember,
    isInvited,
    membershipPolicies: {
      ...membershipInformation?.membershipPolicies,
      canJoin: {
        failureReason: canJoin ? null : { message: '', key: '', args: [] }
      },
      canRequestToJoin: {
        failureReason: canRequestToJoin ? null : { message: '', key: '', args: [] }
      }
    },
    requestToJoinDate
  };
  return updatedContextNode;
}

/**
 * Updates current membership node's data in cache.
 * @param cache the Apollo client cache.
 * @param contextNode the membership node.
 * @param updatedContextNode the updated membership node data to update cache.
 * @param id id of the cache object to be updated.
 * @param action action taken
 */
function updateContextNode<T>(
  cache: ApolloCache<T>,
  contextNode: MembershipInformationFragment,
  updatedContextNode: MembershipInformationFragment,
  id: string,
  action: MembershipAction
): () => void {
  const connection = contextNode?.membershipPolicies;

  async function doUpdate(nodeContext): Promise<void> {
    cache.modify({
      id,
      fields: {
        membershipPolicies() {
          return {
            ...nodeContext.membershipPolicies,
            canJoin: {
              ...nodeContext.membershipPolicies.canJoin,
              __typename: 'PolicyResult'
            },
            canRequestToJoin: {
              ...nodeContext.membershipPolicies.canRequestToJoin,
              __typename: 'PolicyResult'
            }
          };
        },
        isMember() {
          return nodeContext?.isMember;
        },
        isInvited() {
          return nodeContext?.isInvited;
        },
        membersCount() {
          return nodeContext?.membersCount;
        },
        membershipRequestsCount(currentCount) {
          if (action === APPROVE_MEMBERSHIP_REQUEST || action === DENY_MEMBERSHIP_REQUEST) {
            return currentCount - 1;
          }
          return currentCount;
        },
        membershipInvitesCount(currentCount) {
          if (action === CANCEL_INVITE) {
            return currentCount - 1;
          }
          return currentCount;
        },
        requestToJoinDate() {
          return nodeContext?.requestToJoinDate;
        }
      }
    });
  }

  doUpdate(updatedContextNode);
  // return a rollback function
  return (): void => {
    doUpdate(updatedContextNode);
    doUpdate({ ...connection });
  };
}

/**
 * Updates the membership status of a user in a membership node.  Returns a rollback function to reset the UI
 * in case of error.
 *
 * @param cache the Apollo client cache
 * @param membershipInformation the membership node
 * @param action action taken
 * @param isCurrentUser whether the current user is same as the user for whom the action is being taken for
 * @return a rollback function.
 */
function updateUserMembershipStatus<T>(
  cache: ApolloCache<T>,
  membershipInformation: MembershipInformationFragment,
  action: MembershipAction,
  isCurrentUser: boolean
): () => void {
  const id = cache.identify(membershipInformation);
  const membershipInformationAfterAction = getContextNodeAfterAction(
    membershipInformation,
    action,
    isCurrentUser
  );

  return updateContextNode(
    cache,
    membershipInformation,
    membershipInformationAfterAction,
    id,
    action
  );
}

/**
 * Provides variables for mutation
 *
 * @param mutationVariables mutation variables
 * @return variables for mutation
 */
function getMutationVariables(mutationVariables: MutationPayload): MutationPayload {
  return {
    ...mutationVariables,
    nodeId: `node:${mutationVariables.nodeId}`
  };
}

/**
 * Update the member role cache after a member's role change.
 *
 * @param cache The apollo cache
 * @param userData The user data
 * @param contextNode the membership node
 * @param updateInput the request data payload
 */
function updateMemberRoleListCache(
  cache: ApolloCache<{}>,
  userData: UserViewFragment,
  contextNode: ContextNodeFragment,
  updateInput: UpdateRolesInput
) {
  const variables = getCachedQueryVariables(
    cache,
    `${ApolloQueryCacheKey.MEMBER_LIST_FOR_MANAGE_MEMBERS}:${contextNode.id}`
  );

  const memberViewCache = cache.readQuery<MembershipListQuery, MembershipListQueryVariables>({
    query: memberViewsQuery,
    variables
  });

  const queryNodeRoleCache = cache.readQuery<NodeRoleQuery, NodeRoleQueryVariables>({
    query: nodeRoleQuery,
    variables: {
      id: contextNode.id
    }
  });

  const updatedEdges = queryNodeRoleCache.coreNode.roles.edges.filter(role =>
    updateInput.rolesToAdd.some(data => role.node.name === data.roleName)
  );

  const updatedMemberCache = memberViewCache.users.edges.map(data => {
    let { edges } = data.node.roles;
    if (userData.uid === data.node.uid) {
      edges = updatedEdges;
    }
    return {
      ...data,
      node: {
        ...data.node,
        roles: {
          edges,
          __typename: 'RoleConnection'
        }
      }
    };
  });

  async function doUpdate(updatedData): Promise<void> {
    const data: MembershipListQuery = {
      users: {
        ...memberViewCache.users,
        edges: updatedData
      }
    };

    cache.writeQuery<MembershipListQuery, MembershipListQueryVariables>({
      query: memberViewsQuery,
      variables,
      data
    });
  }

  doUpdate(updatedMemberCache);
  // return a rollback function
  return (): void => {
    doUpdate(updatedMemberCache);
    doUpdate(memberViewCache.users.edges);
  };
}

/**
 * Uses the mutate function to update the membership status of a user in a membership node.
 * Returns an object of errors and a rollback function to reset the UI in case of error.
 *
 * @param mutationVariables information required to make the mutation call and update the node thereafter
 * @return an object of errors and a rollback function
 */
async function updateNode(mutationVariables: IUpdateNodeVariables) {
  const {
    mutate,
    membershipInformation,
    action,
    isCurrentUser,
    mutationPayload,
    client,
    onUpdateCompleted
  } = mutationVariables;
  let rollback;
  const variables = getMutationVariables(mutationPayload);

  const { errors } = await mutate({
    variables,
    update: async (cache, { errors: updateErrors }): Promise<void> => {
      if (!(updateErrors?.length > 0)) {
        rollback = updateUserMembershipStatus(cache, membershipInformation, action, isCurrentUser);
        if (onUpdateCompleted) {
          onUpdateCompleted(cache);
        }
      }
    }
  });

  if (!(errors?.length > 0) && client) {
    if (action === REMOVE_MEMBER) {
      client.cache.evict({
        id: `User:${(mutationPayload as RemoveMemberMutationVariables).userId}`
      });
      client.cache.gc();
    }

    if (action === APPROVE_MEMBERSHIP_REQUEST) {
      client.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'users' });
        }
      });
      client.cache.gc();
    }

    if (
      (action === LEAVE_MEMBERSHIP_NODE &&
        membershipInformation?.membershipType === MembershipType.Open) ||
      action === JOIN_MEMBERSHIP_NODE
    ) {
      client.refetchQueries({
        include: [userViewsQuery, nodeQuery, groupHubDetailsQuery]
      });
    }
  }

  return { errors, rollback };
}

/**
 * Function to get query variables for MembershipInvites query
 * @param id context node id
 * @param router Current app router.
 * @return an object of type MembershipInvitesQueryVariables
 */
function getMembershipInvitesQueryVariables(
  id: string,
  router: EndUserRouter
): MembershipInvitesQueryVariables {
  const inviteFilterQueryParamValue = router.getUnwrappedQueryParam(
    EndUserQueryParams.INVITE_BY,
    InviteType.Both
  );
  const inviteFilter = getAsEnum<typeof InviteType>(
    inviteFilterQueryParamValue,
    InviteType,
    InviteType.Both
  );
  const memberSortFromUrl = router.getUnwrappedQueryParam(
    EndUserQueryParams.SORT_BY_INVITE_DATE,
    SortDirection.Desc
  );
  const inviteSorts = getAsEnum<typeof SortDirection>(
    memberSortFromUrl,
    SortDirection,
    SortDirection.Desc
  );

  return {
    id,
    first: 20,
    constraints: {
      inviteType: {
        eq: inviteFilter
      }
    },
    sorts: { inviteDate: { direction: inviteSorts } },
    useFullPageInfo: true,
    useJoinDate: false,
    useAvatar: true
  };
}

export {
  updateNode,
  updateUserMembershipStatus,
  updateMemberRoleListCache,
  getMembershipInvitesQueryVariables
};
